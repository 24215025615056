/* @import url("https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}
/* body[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

body[dir="ltr"] {
  direction: ltr;
  text-align: left;
}


[dir="rtl"] h1 {
  text-align: right; 
}

[dir="ltr"] h1 {
  text-align: left; 
}
[dir="rtl"] h3 {
  text-align: right; 
}

[dir="ltr"] h3 {
  text-align: left;
}
[dir="rtl"] p {
  text-align: right; 
}

[dir="ltr"] p {
  text-align: left; 
}
[dir="rtl"] label {
  text-align: right; 
}

[dir="ltr"] label {
  text-align: left; 
}
[dir="rtl"] dt {
  text-align: right; 
}

[dir="ltr"] dt {
  text-align: left;
}
[dir="rtl"] dd {
  text-align: right; 
}

[dir="ltr"] dd {
  text-align: left; 
}
[dir="rtl"] div {
  text-align: right;
}

[dir="ltr"] div {
  text-align: left; 
} */
